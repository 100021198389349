import React, { useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import MetaTags from '../components/MetaTags/MetaTags';
import HeaderImage from '../components/HeaderImage/HeaderImage';
import Paragraph from '../components/Paragraph/Paragraph';
import { graphql } from 'gatsby';
import _ from 'lodash';
import MenuContext from '../util/MenuContext';
import BaseHead from '../components/BaseHead/BaseHead';

const landing = ({ data, pageContext: { language, languageUrls } }) => {
  const node = data.cms.node;
  const alert = _.first(_.get(data, 'alert.nodeQuery.entities', []));
  const headerImage = _.get(node, 'fieldHeaderImage.entity.fieldMediaImage.headerImage.image.childImageSharp', null);
  const headerImageAlt = _.get(node, 'fieldHeaderImage.entity.fieldMediaImage.alt', null);
  const className = _.get(node, 'fieldPageStyle');
  const menus = {
    top: _.get(data, 'topMenu.menuByName.links', []),
    footer: _.get(data, 'footerMenu.menuByName.links', []),
    popular: _.get(data, 'popularMenu.menuByName.links', [])
  }

  return (
    <MenuContext.Provider value={menus}>
      <Layout language={language} urls={languageUrls} alert={alert} home={true}>
        <main id="content" className={className} >
          {headerImage && <HeaderImage
            image={headerImage}
            alt={headerImageAlt}
            height="high"
            />}
          {node.fieldContent.map(({ entity }) => <Paragraph key={entity.entityId} entity={entity} />)}
        </main>
      </Layout>
    </MenuContext.Provider>
  )
}

const LandingHead = ({ data, pageContext: { language } }) => {
  const node = data.cms.node
  const isContactPage = _.get(node, 'path.alias', null) === '/contact'

  const getOBI4wanGuid = () => {
    if (language.toLowerCase() === 'en') {
      return process.env.GATSBY_OBI4WAN_GUID_EN || null
    }

    return process.env.GATSBY_OBI4WAN_GUID || null
  }

  const guid = getOBI4wanGuid()

  useEffect(() => {
    if (window.OBI && typeof window.OBI.chat === 'function') {
      window.OBI.chat({
        widgetStyle: { color: "#e17000", textColor: "#000000", fontFamily: "RO Sans" }

      });
    }
  }, []);

  return (
    <BaseHead>
      <MetaTags node={node} />
      {
        isContactPage && guid && <script
        id="obi-chat-launcher" src="https://cloudstatic.obi4wan.com/chat/obi-launcher.js"
        data-guid={guid}
        data-config="true"
        >
        </script>
      }
    </BaseHead>
  )
}

export const Head = LandingHead

export const query = graphql`
  query landingQuery ($vid: String!, $language: CMS_LanguageId!) {
    cms {
      node: nodeRevisionById(id: $vid, language: $language) {
        ... Landing
      }
    }

    alert: cms {
      nodeQuery(filter: {
        conditions: [
          { operator: EQUAL, field: "type", value: "alert" }
          { operator: EQUAL, field: "status", value: "1" }
        ]
      }, limit: 1) {
        count
        entities(language: $language) {
          entityId
          entityBundle
          ... on CMS_NodeAlert {
            nid
            status
            title
            fieldAllPages
            fieldIntroText
            fieldDetailLink {
              url {
                path
              }
            }
            fieldDismissable
          }
        }
      }
    }

    topMenu: cms {
      menuByName(name: "top", language: $language) {
        links {
          label
          url {
            path
          }
        }
      }
    }

    footerMenu: cms {
      menuByName(name: "footer", language: $language) {
        links {
          ... Link
          links {
            ... Link
          }
        }
      }
    }

    popularMenu: cms {
      menuByName(name: "popular", language: $language) {
        links {
          label
          url {
            path
          }
        }
      }
    }
  }

  fragment Landing on CMS_NodeLanding {
    title
    entityMetatags {
      key
      value
    }
    path {
      alias
    }
    fieldHeaderImage {
      entity {
        ...HeaderImage
      }
    }
    fieldPageStyle
    fieldContent {
      entity {
        entityId
        entityBundle
        ...Container
      }
    }
  }
`

export default landing
